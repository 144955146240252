import { partners } from "./constants/datalayer.constants"

const gtmDatalayer: () => { push: (payload: any) => void } = () =>
  (window as any).dataLayer || []

export const datalayer = {
  init: (payload: { partner: string; partnerType: string }) => {
    gtmDatalayer().push(payload)
  },
  setTransactionId: (transactionId: string) => {
    gtmDatalayer().push({
      transactionID: transactionId
    })
  },
  setFlowPage: (flowPage: string) => {
    sessionStorage.setItem("pacifico-flow-page-datalayer", flowPage)
    gtmDatalayer().push({
      flujo: flowPage
    })
  },
  initDefault: () =>
    datalayer.init({
      partner: partners.pacifico,
      partnerType: partners.pacifico
    }),
  push: (payload: any) => gtmDatalayer().push(payload)
}
